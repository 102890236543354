import { Svg } from "atoms/SvgIcon";

const IconCheckMark = ({ ...rest }) => {
    return <Svg viewBox='0 0 10 10' type='IconCheckMark' {...rest} >
            <circle cx="4.66926" cy="6.58369" r="4.66926" fill="#B0EF8F"/>
            <path d="M7.61106 5.71984C7.46164 5.94397 5.58771 9.06615 4.66943 10.5992L1.16748 5.27627L2.00795 4.41245L4.66943 6.32685C5.06631 5.48638 6.41573 3.89883 6.86398 3.43191C7.51394 2.75486 9.50211 0.88716 10.6694 0H12.0002C11.1364 0.44358 7.79783 5.43969 7.61106 5.71984Z" fill="#009045"/>
    </Svg>
};

export default IconCheckMark;
