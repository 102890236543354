import Alert from 'molecules/Alert';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Controller } from "react-hook-form";
import { GetPincodeData, GetServiceblePincode } from 'services/user.service';
import Modal from '../../atoms/Modal';
import Button from './../../atoms/Button';
import ReactSelect from './../../atoms/forms/ReactSelect';
import Input from './../../atoms/Input';
import Checkbox from './../../molecules/Forms/Checkbox';
import BodyForModal from './BodyForModal';
import FooterForModal from './FooterForModal';
import HeaderForModal from './HeaderForModal';
const appId = process.env.NEXT_PUBLIC_APP_ID;

import { CHANNEL } from 'constant/warehouse_pincode_mapping';
import { getAuth } from 'services/identity.service';
import { useRouter } from 'next/router';
import { handleAnalyticEventHandleAddressUpdate } from 'utils/ga-analytic';
import googleAnalytics from "../../assets/mo_engage/order_flow_ga.json"
import { useAddressContext } from 'components/context/addressContext';

const ModalForAddResellerPincode = ({
    className,
    label,
    setValue,
    setError,
    clearErrors,
    watch,
    isModalOpen,
    handleModalStateChange,
    stateList,
    register,
    handleSubmit,
    onSubmit,
    errors,
    control,
    isForReseller = true,
    setStateCode,
    tradeName,
    setTradeName,
    buttonIsDisabled,
    editPageData,
}) => {

    
    let router =useRouter()
    const analyticEntity = googleAnalytics.entity;
    const handleCreateAddress = (data) => {
        setTradeName("")
        onSubmit(data);
    }
    const auth = getAuth();
    const userType = auth?.user?.userType

    const { setIsAddressModal
     } = useAddressContext()

     useEffect(()=>{
        setIsAddressModal(false)
     },[])
  
    const handlePincodeData = async (pincode) => {
        const result = await GetPincodeData(pincode || watch('pincode'))
        if (result.entity) {
            const servicableResult = await GetServiceblePincode(result.entity.pincode, isForReseller ? CHANNEL.B2B2C : CHANNEL.B2B)
            if (servicableResult.status) {
                if (result.entity.block == 'NA') {
                    setValue('city', result.entity.district)
                } else {
                    setValue('city', result.entity.block)
                }
                setValue('state', stateList.find(item => item.label.toLowerCase() === result.entity.state.toLowerCase())?.value)
                setStateCode(stateList.find(item => item.label.toLowerCase() === result.entity.state.toLowerCase())?.stateCode)
                clearErrors('pincode')
                clearErrors('state')
                clearErrors('city')

            } else {
                setError('pincode', { message: 'Sorry. NirogStreet is yet to bring its services to your pincode. Please change your address and try again.' })
                return
            }
        }
        else if (!result.status) {
            setError('pincode', { message: 'Enter your Valid 6 digit Pin Code.' })
        }
    }

    useEffect(() => {
        if (!watch('pincode') || watch('pincode') === null) return;

        let pin_code = watch('pincode').toString()
        if (pin_code?.length === 6) {
            handlePincodeData(watch('pincode'))
        }
        if (watch('pincode')?.length < 6) {
            setValue('city', '')
            setValue('state', '')
        }
        
    }, [watch('pincode')]);

    useEffect(() => {
        setValue('pincode', editPageData?.pin_code);
    }, [editPageData]);
    
    return (
        <Modal
            type="modalTypeZero"
            containerClass={`${isModalOpen || 'hidden'}`}
            className={`${className} max-w-109.5 w-full`}
            handlerModalClose={() => handleModalStateChange()}
            isModalOpen={isModalOpen}
            dataAutomation='add-address'
        >
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(handleCreateAddress)() }} className='h-full'>
                <div className='flex flex-col max-h-200 relative'>

                    <HeaderForModal
                        className='flex-shrink-0'
                        isCloseButton={true}
                        handlerModalClose={() => handleModalStateChange()}
                        dataAutomation='add-address'
                    >{label}</HeaderForModal>

                    <div className='relative overflow-y-auto flex-grow customScrollbar'>
                        <BodyForModal type="bodyType1" className="pb-3">

                            <Alert effect="withoutEffect" type="warning_v3" className="w-full mb-2 z-1">
                                <div> 
                                <span className='text-semantic-error_base'>*</span>
                                <span>Please ensure the pincode is correct; otherwise, you may encounter difficulties with medicine search and delivery.</span>
                                </div>   
                            </Alert>
                                <Input
                                    type="text"
                                    placeholder='Pincode*'
                                    id="pincode"
                                    register={register}
                                    error={errors?.pincode}
                                    isRequiredField={true}
                                    minLength={6}
                                    maxLength={6}
                                    disabled={(`${editPageData?.gstVerified}` == 1 || editPageData?.isResellerDefaultAddress) ? true : false}
                                />
                                <Input
                                    type="text"
                                    placeholder='City*'
                                    id="city"
                                    register={register}
                                    error={errors?.city}
                                    isRequiredField={true}
                                    disabled={(`${editPageData?.gstVerified}` == 1 || editPageData?.isResellerDefaultAddress) ? true : false}
                                />

                            <Controller
                                control={control}
                                name="state"
                                render={({ field: { onChange, name, value } }) => (
                                    <ReactSelect
                                        name={name}
                                        isRequiredField
                                        placeholder="Select State*"
                                        onChange={(data) => {
                                            onChange(data?.value)
                                        }}
                                        value={stateList?.find((c) => c.value === value || null)}
                                        options={stateList}
                                        error={errors?.state}
                                        isDisabled={true}
                                    />
                                )}
                            />

                        </BodyForModal>
                    </div>
                    <FooterForModal className="px-3.5 xs:px-5 sm:px-8 pt-3 pb-3 md:pb-4 bg-basic-white relative z-10">
                        <Button size='medium' className='rounded-lg w-[300px] mx-auto' dataAutomation='save-address' disabled={buttonIsDisabled}>Save pincode</Button>
                    </FooterForModal>
                </div>

            </form>
        </Modal>
    )
}

ModalForAddResellerPincode.propTypes = {
    className: PropTypes.string,
    isModalOpen: PropTypes.bool,
    handleModalStateChange: PropTypes.func,
    isAddressModal: PropTypes.bool
}

export default ModalForAddResellerPincode

