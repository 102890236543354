import React from 'react';
import PropTypes from 'prop-types';

import Badge from '../atoms/Badge';
import Text from '../atoms/Text';
import RadioButton from './../atoms/forms/RadioButton';
import IconChevronRight from 'atoms/SvgIcons/IconChevronRight.svg';

const Rounded = {
    default: '',
    lg: 'rounded-lg'
}

const LinkNavigator = ({ className, GroupHeading, labelClass, totalBadge, isRadio, isSelected, setIsSelected,
    radioGroupName, onClick, isCoupenApplied, value, selectPaymentType, rounded, showRightArrow, dataAutomation, isDisabled, description, ...rest }) => {
    return (
        <label className={`${className} ${Rounded[rounded]} ${(description) && 'items-start'} linkNavigator`} {...rest} onClick={onClick} data-automation={dataAutomation + '-label'}>

            {isRadio && <RadioButton radioGroupName={radioGroupName} isLabel={false} value={value}
                handlerRadioChange={selectPaymentType}
                isSelected={isSelected} isDisabled={isDisabled} {...(isRadio && { dataAutomation })} />}

            <div className={`${(description) ? 'flex-col' : ''}  flex-grow flex gap-2`}>
                <Text className={`${!(typeof totalBadge === 'undefined') ? 'text-gray-500 font-semibold' : labelClass}`} type='overline'>{GroupHeading}</Text>
                {!(typeof totalBadge === 'undefined') && <Badge className='flex-shrink-0 text-primary2-900 bg-primary2-300'>{totalBadge}</Badge>}
                {description && <Text type='caption' className='text-gray-500'>
                    {description}
                </Text>}
            </div>
            {
                showRightArrow && <button>
                    <IconChevronRight className="w-2.5 fill-gray-400 group-hover:fill-primary1-500" />
                </button>
            }
            
        </label>
    )
}

LinkNavigator.defaultProps = {
    className: '',
    rounded: 'default',
}

LinkNavigator.propTypes = {
    className: PropTypes.string,
    GroupHeading: PropTypes.string,
    rounded: PropTypes.oneOf(['default', 'lg']),
}

export default LinkNavigator
