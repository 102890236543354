import React from 'react'
import PropTypes from 'prop-types'
import Heading from './Heading'
import { FontWeight } from 'constant/ui'

const TYPE = {
    regular: 'regular',
    gradient: 'gradient',
}

const HeadingHR = (props) => {
    const { children, className = '', type, fontWeight = 700, ...rest } = props
    return type == TYPE.gradient ? (

        <div className={`${className} justify-center flex items-center before:flex-grow after:flex-grow after:h-[1px] before:h-[1px] before:bg-gray-300 after:from-[#91d4ed] after:to-[#fff] after:bg-gradient-to-r before:from-[#91d4ed] before:to-[#fff] before:bg-gradient-to-l after:max-w-[148px] before:max-w-[148px]`} {...rest}>
            <Heading type='h1' className='px-2 text-2xl md:text-3xl' textColor='text-primary1-500' weight={FontWeight[fontWeight]}>{children}</Heading>
        </div>
    ) : (
        <div className={`${className} flex items-center before:flex-grow after:flex-grow after:h-[1px] before:h-[1px] before:bg-gray-300 after:bg-gray-300`} {...rest}>
            <div className={`${FontWeight[fontWeight]} text-gray-500 px-7 md:px-8`}>{children}</div>
        </div>
    )
}

export default HeadingHR

HeadingHR.defaultProps = {
    className: '',
}

HeadingHR.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}

